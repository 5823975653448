import * as echarts from "echarts";

let base = {
  initChart(id, series, options) {
    document.getElementById(id).removeAttribute("_echarts_instance_")
    let chart = echarts.init(document.getElementById(id));
    chart.setOption({
      tooltip: {
        trigger: 'axis'
      },
      title: Object.assign({}, options.title ? options.title : {}),
      xAxis: Object.assign({
        type: 'category',
        boundaryGap: false,
        data: [],
        show: false
      }, options.xAxis ? options.xAxis : {}),
      yAxis: Object.assign({
        type: 'value',
        show: false
      }, options.yAxis ? options.yAxis : {}),
      series: [
        Object.assign({
          data: options.series && options.series.data ? options.series.data : [],
          type: series.type ? series.type : 'line',
          color: series.color ? series.color : ['#7749fc'],
        }, series ? series : {})
      ],
      grid: options.grid ? options.grid : {}
    })
  }
}

export default base