<template>
  <!--  FB、TT、微软、google广告账户趋势 & 消耗排名 可复用-->
  <el-card class="mb-20 daily-spend">
    <template #header>
      <el-row justify="space-between">
        <span class="SubHead">广告账户趋势</span>
        <el-form inline>
          <el-form-item>
            <el-select
              class="spent-type-selector"
              size="small"
              v-model="dailyForm.spent_type"
              @change="searchDailySpent"
            >
              <el-option label="按天" value="1"></el-option>
              <el-option label="按周" value="2"></el-option>
              <el-option label="按月" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              type="daterange"
              size="small"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="X"
              unlink-panels
              v-model="dailyForm.spent_time"
              :shortcuts="base.getDatePickerShortcuts()"
              :disabled-date="base.getDefaultDisabledDate"
              @blur="searchDailySpent"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </el-row>
    </template>
    <el-row :gutter="10">
      <el-col :span="18">
        <div id="daily-spend-chart" class="daily-spend-chart"></div>
      </el-col>
      <el-col :span="6">
        <span class="SmallHead">广告消耗量排名</span>
        <el-empty v-if="dailySpendRank.length < 1" :image-size="200">
          <template #image>
            <img src="@/assets/empty.gif" />
          </template>
        </el-empty>
        <el-descriptions v-else class="rank-list" :column="3" direction="vertical">
          <template v-for="item in dailySpendRank" :key="item.top">
            <el-descriptions-item :width="50" class="top-num">
              <el-tag type="info" size="small" :effect="item.top <= 3 ? 'dark' : 'light'">
                {{ item.top }}
              </el-tag>
            </el-descriptions-item>
            <el-descriptions-item :width="150">{{ item.account_id }}</el-descriptions-item>
            <el-descriptions-item>$ {{ $filters.thousands(item.sum_spent) }}</el-descriptions-item>
          </template>
        </el-descriptions>
      </el-col>
    </el-row>
  </el-card>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import base from '@/base/filters/base'
import { ElMessage } from 'element-plus'
import api from '@/request/api/account'
import tools from '@/views/ads/analysis/common/tools'

const props = defineProps({
  type: { type: String, default: 'bing' },
})

let dailyForm = ref({
  spent_type: '1',
  spent_time: base.getDefaultDuration('30'),
  type: 1,
})

let dailySpendRank = ref([])

let searchDailySpent = async () => {
  if (!dailyForm.value.spent_time || dailyForm.value.spent_time.length < 2) {
    return ElMessage.warning('请选择时间范围')
  }

  const res = await (props.type === 'google'
    ? api.google.getDashboardDaily(dailyForm.value)
    : api.bing.getDashboardDaily(dailyForm.value))
  dailySpendRank.value = res.data && res.data.account_spent ? res.data.account_spent : []

  tools.initChart(
    'daily-spend-chart',
    { type: 'line', color: ['#2b82ff'] },
    {
      title: {
        text: '账户消耗',
        textStyle: { color: '#595959', fontSize: 14, fontWeight: 600 },
        left: 10,
      },
      yAxis: { show: true },
      xAxis: Object.assign({ show: true }, res.data.spent.xAxis),
      series: res.data.spent.series,
      grid: { top: '60px', left: '70px', right: '40px', bottom: '40px' },
    }
  )
}

onMounted(() => {
  searchDailySpent()
})
</script>

<style scoped>
.daily-spend .el-form-item {
  margin-bottom: 0 !important;
  margin-right: 0 !important;
}

.daily-spend .SubHead {
  align-self: center;
}

.daily-spend .daily-spend-chart {
  height: 350px;
  width: 100%;
}

.rank-list {
  padding-top: 12px;
}

.rank-list .el-tag {
  border-radius: 50%;
}

.spent-type-selector {
  width: 90px;
  margin-right: 10px;
}
</style>
